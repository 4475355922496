
import { Component, Mixins } from "vue-property-decorator";
import { VuetifyMixin, PaginatedTableMixin, MyAthleteMixin, StringsMixin, AthleteRoutingMixin, UpgradeNowMixin, BAIconsMixin } from "@/mixins";
import { DataTableHeader } from "vuetify";
import { BASchoolModel } from "@/models/baSchool"
import { BASchoolDivision, BASchoolConference, BASchoolStateOrProvince, CombinedStatesAndProvincesType, BASchoolType, BACampusSetting, BAPopularMajor } from "@/../types/enums"
import { baSchoolStore, notificationStore } from "@/store";
import { Route } from "vue-router";
import * as Routes from "@/../types/constants/web_client_user.routes";
import SharingInfoProvider from "@/components/hoc/SharingInfoProvider.vue";
import ShareProfile from "@/components/profile/recruiting/ShareProfile.vue";
import SubscriptionInfoProvider from "@/components/hoc/SubscriptionInfoProvider.vue";
import FeatureNotAvailableDialog from "@/components/subscriptions/FeatureNotAvailableDialog.vue";
import SchoolSearchSvg from "@/components/svg/SchoolSearchSvg.vue";

@Component({
	components: {
        SharingInfoProvider,
        ShareProfile,
        SubscriptionInfoProvider,
        FeatureNotAvailableDialog,
        SchoolSearchSvg,
    }
})
export default class SchoolSearch extends Mixins(VuetifyMixin, PaginatedTableMixin, MyAthleteMixin, StringsMixin, AthleteRoutingMixin, UpgradeNowMixin, BAIconsMixin) {
	sharingProfile: boolean = false;
    mySportOnly: boolean = true;
    myGenderOnly: boolean = true;
    filterPanels = [];
    divisionsFilter: BASchoolDivision[] = [];
    conferencesFilter: BASchoolConference[] = [];
    statesFilter: CombinedStatesAndProvincesType[] = [];
    schooltypeFilter: BASchoolType[] = [];
    campussettingFilter: BACampusSetting[] = [];
    popularmajorFilter: BAPopularMajor[] = [];
    showUpgradeNow = false;
    search: string = '';

    localForagePersistFields: Array<string | [string, any]> = [
        ['search', ''],
        ['mySportOnly', true],
        ['myGenderOnly', true],
        ['filterPanels', []],
        ['divisionsFilter', []],
        ['conferencesFilter', []],
        ['statesFilter', []],
        ['popularmajorFilter', []],
        ['campussettingFilter', []],
        ['schooltypeFilter', []],
    ]
        
    get HasFilters(): boolean {
        return( ( this.IsNotEmpty(this.search) ) ||
                ( this.IsNotEmptyArray(this.divisionsFilter) ) ||
                ( this.IsNotEmptyArray(this.conferencesFilter) ) ||
                ( this.IsNotEmptyArray(this.statesFilter) ) ||
                ( this.IsNotEmptyArray(this.popularmajorFilter) ) ||
                ( this.IsNotEmptyArray(this.campussettingFilter) ) ||
                ( this.IsNotEmptyArray(this.schooltypeFilter) )
              )
    }

    get DivisionsList() {
        return BASchoolDivision;
    }
    divisionsRemove(division: BASchoolDivision) {
        const index = this.divisionsFilter.findIndex(item => item === division);
        this.divisionsFilter.splice(index, 1);
        this.updateTable();
    }

    get ConferencesList() {
        return BASchoolConference;
    }
    conferencesRemove(conference: BASchoolConference) {
        const index = this.conferencesFilter.findIndex(item => item === conference);
        this.conferencesFilter.splice(index, 1);
        this.updateTable();
    }

    get StatesList() {
        return BASchoolStateOrProvince;
    }
    statesRemove(state: CombinedStatesAndProvincesType) {
        const index = this.statesFilter.findIndex(item => item === state);
        this.statesFilter.splice(index, 1);
        this.updateTable();
    }

    get SchoolTypesList() {
        return BASchoolType;
    }
    schooltypeRemove(schooltype: BASchoolType) {
        const index = this.schooltypeFilter.findIndex(item => item === schooltype);
        this.schooltypeFilter.splice(index, 1);
        this.updateTable();
    }

    get PopularMajorsList() {
        return BAPopularMajor;
    }
    popularmajorRemove(popularmajor: BAPopularMajor) {
        const index = this.popularmajorFilter.findIndex(item => item === popularmajor);
        this.popularmajorFilter.splice(index, 1);
        this.updateTable();
    }

    get CampusSettingList() {
        return BACampusSetting;
    }
    campussettingRemove(campussetting: BACampusSetting) {
        const index = this.campussettingFilter.findIndex(item => item === campussetting);
        this.campussettingFilter.splice(index, 1);
        this.updateTable();
    }

	mounted() {
		this.tableOptions.sortBy = ['name'];
	}

    get PageLoading(): boolean {
        return this.tableUpdatePending || baSchoolStore.loadingBASchools || this.IsAthleteLoading;
    }
    async loadTable() {
        try {
            var query = this.TableQuery<BASchoolModel>(this.search, ['name', 'location.city', 'location.state']);
            if( this.mySportOnly ) query.$match = {...query.$match, ...{ 'sports.name': this.TheAthleteProfile.Sport.name } };
            if( this.myGenderOnly ) query.$match = {...query.$match, ...{ 'sports.gender': this.TheAthleteProfile.SportGender } };
            if( !!this.divisionsFilter && this.divisionsFilter.length > 0 ) { query.$match = {...query.$match, ...{ 'sports.division': { $any: this.divisionsFilter } } } };
            if( !!this.conferencesFilter && this.conferencesFilter.length > 0 ) { query.$match = {...query.$match, ...{ 'sports.conference': { $any: this.conferencesFilter } } } };
            if( !!this.statesFilter && this.statesFilter.length > 0 ) { query.$match = {...query.$match, ... { 'location.state': { $any: this.statesFilter } } } };
            if( !!this.campussettingFilter && this.campussettingFilter.length > 0 ) { query.$match = {...query.$match, ... { 'campusSetting': { $any: this.campussettingFilter } } } };
            if( !!this.schooltypeFilter && this.schooltypeFilter.length > 0 ) { query.$match = {...query.$match, ... { 'schoolType': { $any: this.schooltypeFilter } } } };
            if( !!this.popularmajorFilter && this.popularmajorFilter.length > 0 ) { query.$in = { 'popMajors': this.popularmajorFilter } };

            await baSchoolStore.loadBASchools({query, options: this.TableQueryOptions});
            this.dataItemsCount = baSchoolStore.numBASchools;
        } catch(e) {
			notificationStore.pushSnackbarError({message: `Error loading schools: ${e}`});
		}
    }

    clearFilters() {
        this.search = "";
        this.divisionsFilter = [];
        this.conferencesFilter = [];
        this.statesFilter = [];
        this.popularmajorFilter = [];
        this.campussettingFilter = [];
        this.schooltypeFilter = [];
        this.filterPanels = [];
        this.loadTable();
    }

	gotoURL(url: string) {
		window.open(url, '_blank')
	}

	BASchoolPageRoute(id: string): Partial<Route> {
		return {
			name: Routes.BASchoolDetails,
			params: {
				athleteId: this.TheAthleteId,
				schoolId: id,
			}
		};
	}

    get BASchools(): BASchoolModel[] {
        return baSchoolStore.baSchools;
    }

    get TableHeaders(): DataTableHeader[] {
        var headers: DataTableHeader[] = [ { text: 'Name', value: 'name', sortable: true } ]

        headers.push( { text: 'Location', value: 'location', sortable: false } );
        if( this.IsLargeScreen ) {
            headers.push( { text: 'Division', value: 'division', sortable: false } );
            headers.push( { text: 'Conference', value: 'conference', sortable: false } );
        }
        headers.push( { text: '', value: 'actions', sortable: false } );
        headers.push( { text: '', value: 'data-table-expand', sortable: false } );

        return headers;
    }

    onClickSchool(schoolId: string, schoolSearchAvailable: boolean ) {
        if( schoolSearchAvailable) {
            this.$router.push(this.BASchoolPageRoute(schoolId));
        } else {
            this.showUpgradeNow = true;
        }
    }
    onClickMySportOnly() {
        if( !this.mySportOnly ) this.myGenderOnly = false;
        this.updateTable();
    }
}
